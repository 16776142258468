<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">Increment Request</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">

                         <!-- advance serach -->
            <div class="d-flex">
                <div class="d-flex flex-column me-2">
                    <label class="col-form-label">Program</label>
                    <div style="width: 250px">
                        <v-select label="name" v-model="program" :options="programs" :clearable="false"
                            :selectable="(options) => program.short_name != options.short_name "
                            @option:selected="findBatch_programFilter(program)">
                        </v-select>
                    </div>
                </div>
                <div class="d-flex flex-column me-2">
                    <label class="col-form-label">Batch</label>
                    <div style="width: 180px">
                        <v-select label="batch_no" v-model="batch" :options="batchs" :clearable="false"
                        :selectable="(options) =>  batch.id != options.id "
                        @option:selected="FilterData(program, batch)"
                        >
                        </v-select>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <!-- <div class="col-form-label d-none">hfhd</div> -->
                    <div @click="refreshData()" class="icon-css">
                        <i style="color: #4a81d4" class="fe-rotate-cw"></i>
                    </div>
                </div> 
            </div>  
                <!--end advance serach -->

                        <div class="d-flex justify-content-end">
                            <router-link :to="{ name: 'increment-management-request-create' }">
                                <button v-show="!loading" type="button"
                                    class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                                    v-if="hasPermissions(['increment-management-create'])">
                                    <i class="mdi mdi-plus-circle"></i> Add Record
                                </button>
                            </router-link>
                        </div>
                        <place-holder v-if="loading"></place-holder>
                        <div class="table-responsive">
                            <table class="table table-striped dt-responsive w-100 mb-3"
                                id="request-management-list-datatable" v-show="!loading">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Program name</th>
                                        <th>Batch</th>
                                        <th>Level</th>
                                        <th>Issued Date</th>
                                        <th>Effective Date</th>
                                        <th>Requestor</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in requestManagement" :key="index">
                                        <td>{{ ++index }}</td>
                                        <td>{{ item.program_name }}</td>
                                        <td>{{ item.program_batch_no }}</td>
                                        <td>{{ item.level }}</td>
                                        <td>{{ new Date(item.issued_date).toISOString().slice(0, 10) }}</td>
                                        <td>{{ new Date(item.effective_date).toISOString().slice(0, 10) }}</td>
                                        <td>{{ employees.find((employee) => employee.emp_id == item.requestor)?.name}}</td>
                                        <td>
                                            <span>
                                                <router-link class="action-icon" :to="{
                                                    name: 'increment-management-request-update',
                                                    params: { id: item.id },
                                                }">
                                                    <i class="mdi mdi-square-edit-outline"></i>
                                                </router-link>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
//Datatable Orientation Criteria
import "datatables.net/js/jquery.dataTables.min.js";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
//Mixins
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {

    mixins: [userHasPermissions],
    setup() {
        const toast = useToast();
        return { toast };
    },
    components:{
        vSelect
    },
    data() {
        return {
            requestManagement: [],
            employees: this.$store.getters["odoo/getAllEmployees"],
            programs: [],
            batchs : [],
            program : "",
            batch : "",
            loading: false,
            baseUrl: process.env.VUE_APP_BASE_URL,
            baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
        };
    },
    methods: {
        async getAllRequestorIncrement() {
            this.loading = true;
            this.$Progress.start();
            await axios
                .get(`${this.baseUrl}admin/v2/increment-management-request`)
                .then((response) => {
                    this.requestManagement = response.data.data;
                    this.loading = false;
                    this.$Progress.finish();
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                });
            $("#request-management-list-datatable").DataTable().draw();
        },
        clearFilter() {
            $.fn.dataTable.ext.search.pop();
            $("#request-management-list-datatable").DataTable().draw();
        },
        async findBatch_programFilter(program) {
            this.batch = "";
            this.loading = true;
            await axios
            .get(`${this.baseUrl}admin/v1/program-batches/list/?program_id=${program.id}`)
            .then((response) => {
                    this.batchs = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.toast.error("Not Found Batch!");
                });
    
                this.clearFilter();
                $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                    const check_program = program?.short_name == data[1] ? true : false;
                    console.log('checl-prog', check_program);
                    return check_program ? 1 : 0;
                });
                $("#request-management-list-datatable").DataTable().draw(); 
        },
    FilterData(program, batch) {
            this.clearFilter();
            // alert('reach');
            $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
                const check_program = program?.short_name == data[1] ? true : false;
                const check_batch = batch?.batch_no == data[2] ? true : false;
                
                return (check_program && check_batch) ? true : false;
            });
            $("#request-management-list-datatable").DataTable().draw(); 
        },
    refreshData() {
      this.requestManagement = [];
      this.batchs = [];
      this.batch = "";
      this.program = "";
      this.clearFilter();
      this.getAllRequestorIncrement();
    },
    async getPrograms() {
      this.loading = true;
      this.$Progress.start();
      await axios
      .get(`${this.baseUrl}admin/v1/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },
    },
    created() {
        this.clearFilter();
        this.getAllRequestorIncrement();
        this.getPrograms();
    },
};
</script>

<style>
.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #4a81d4;
    border-color: #4a81d4;
}
</style>
  